'use strict'

$id = $('body').attr('id')
$class = $('body').attr('class')

resizeFlg = {}
resizeFlg.pc = true
resizeFlg.sp = true
firstView = ''

# pcFixCv = ()->
#   console.log('fixed:PC')
#   $(window).on('scroll', ()->
#     if $(this).scrollTop() > 300
#       $('.js-fixed-contents').addClass('is-show')
#     else
#       $('.js-fixed-contents').removeClass('is-show')
#   )

spFixCv = ()->
  console.log('fixed:SP')
  if $('.p-fixed-contents').length
    # offset = if $('.p-header__inquiry').length > 0 then $('.p-header__inquiry').offset().top + $('.p-header__inquiry').height() - $('.p-header__inner').outerHeight() else $('.p-header__inner').outerHeight()
    if $('body')[0].classList.contains('case') and $('body')[0].classList.contains('details')
      offset = $('.p-case-info').offset().top + $('.p-case-info').outerHeight() - window.outerHeight
    else
      offset = $('.p-header__inner').outerHeight()

    $('.p-fixed-contents.js-headroom').headroom({
      offset: offset
    })

    $(window).on('scroll',
      ()->
        # if ($(this).scrollTop() + $(window).height()) > $('.p-footer .p-footer__inquiry').offset().top + $('.p-footer .p-footer__inquiry').height()
        if ($(this).scrollTop() + $(window).height()) > $('.p-footer').offset().top
          $('.p-fixed-contents.js-headroom').addClass('is-bottom')
        else
          $('.p-fixed-contents.js-headroom').removeClass('is-bottom')
    )
  # else
  #   $('.l-cv-menu.js-headroom').headroom({
  #     offset: $('.p-maintitle').offset().top + $('.p-maintitle').height() - $('.p-header__inner').outerHeight()
  #   })

  #   $(window).on('scroll',
  #     ()->
  #       # if ($(this).scrollTop() + $(window).height()) > $('.p-footer .p-footer__inquiry').offset().top + $('.p-footer .p-footer__inquiry').height()
  #       if ($(this).scrollTop() + $(window).height()) > $('.p-footer').offset().top
  #         $('.l-cv-menu.js-headroom').addClass('is-bottom')
  #       else
  #         $('.l-cv-menu.js-headroom').removeClass('is-bottom')
  #   )

if window.matchMedia('(max-width:750px)').matches
  # SP
  firstView = 'SP'

  spFixCv()
else
  # PC
  firstView = 'PC'

  spFixCv()
  # pcFixCv()

$(window).on('resize',
  ()->
    if window.matchMedia('(max-width:750px)').matches
      if firstView is 'PC' and resizeFlg.sp is true or
      firstView is false and resizeFlg.sp is true
        # PC から SP へ

        spFixCv()

        resizeFlg.pc = true
        resizeFlg.sp = false
        firstView = false
    else
      if firstView is 'SP' and resizeFlg.pc is true or
      firstView is false and resizeFlg.pc is true
        # SP から PC へ

        spFixCv()
        # if $('.p-fixed-contents').length
        #   $('.p-fixed-contents.js-headroom').headroom('destroy')
        # else
        #   $('.l-cv-menu.js-headroom').headroom('destroy')

        # pcFixCv()

        resizeFlg.pc = false
        resizeFlg.sp = true
        firstView = false
)
